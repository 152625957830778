import React, { useRef, useState, useEffect, useCallback, useContext } from "react";
import styled from "styled-components";
import gsap from "gsap";
import loadable from '@loadable/component'
import { StaticImage } from "gatsby-plugin-image";
import { ScreenContext } from '@components/Layout'

import media from "@styles/media";
import colors from "@styles/colors";
import text from "@styles/text";

import useMedia from "@hooks/useMedia";

const LocationSVGR = loadable(() => import("@components/LocationSVGR"));

const Locations: React.FC = () => {
  const screen = useContext(ScreenContext)

  const wrapperRef = useRef(null);
  const mapRef = useRef(null);

  const [xOffset, setXOffset] = useState(0);
  const [yOffset, setYOffset] = useState(0);
  const [triggerOH, setTriggerOH] = useState(false);
  const [triggerCA, setTriggerCA] = useState(false);
  const [triggerFL, setTriggerFL] = useState(false);
  const [triggerTX, setTriggerTX] = useState(false);

  const parallaxIt = useCallback((e, target, movement) => {
    const container = wrapperRef.current;
    const relX = e.pageX - container.offsetLeft;
    const relY = e.pageY - container.offsetTop;

    let x =
      ((relX - container.offsetWidth / 2) / container.offsetWidth) * movement;
    let y =
      ((relY - container.offsetHeight / 2) / container.offsetHeight) * movement;

    setXOffset(x);
    setYOffset(y);

    gsap.to(target, { x: x / 2, y: y / 2 });
  }, [])

  const onMouseMove = useCallback((e) => {
    parallaxIt(e, "#map", -30);
  }, [parallaxIt])

  const tlStart = useMedia(
    "top top",
    "top top",
    "top-=40% top",
    "top-=30% top"
  );

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: tlStart,
      },
    });
    tl.to(
      mapRef.current,
      {
        duration: 0.5,
        opacity: 1,
      },
      0
    );
    tl.call(setTriggerOH, [true], 0);
    tl.call(setTriggerCA, [true], 0);
    tl.call(setTriggerTX, [true], 0);
    tl.call(setTriggerFL, [true], 0);

    return () => {
      tl.kill();
    };
    
  }, [mapRef, wrapperRef, tlStart, setTriggerOH, setTriggerCA, setTriggerTX, setTriggerFL]);

  return (
    <Wrapper ref={wrapperRef} onMouseMove={onMouseMove}>
      <TopSection>
        <Title>Our locations</Title>
        <HR />
      </TopSection>
      <Map
        id="map"
        ref={mapRef}
      >
        {screen.mobile && <StaticImage
          src={'../../images/png/mapMobile.png'}
          alt="US map"
          placeholder="blurred"
          style={{width: '100%', height: "100%"}}
        />}
        {!screen.mobile && <StaticImage
          src={'../../images/png/map.png'}
          alt="US map"
          placeholder="blurred"
          style={{width: '100%', height: "100%"}}
        />}

      </Map>

      <Florida>
        <LocationSVGR
          startingX={50}
          startingY={195}
          startingCX={34.5}
          startingCY={195.5}
          xOffset={xOffset}
          yOffset={yOffset}
          location="florida"
          locationText="Tampa, Florida"
          type="Headquarters"
          address1="1503 US 301, Suite 3, Clair Mel"
          address2="City, FL 33619"
          trigger={triggerFL}
        />
      </Florida>

      <Ohio>
        <LocationSVGR
          startingX={34.4934}
          startingY={285.081}
          startingCX={34.5}
          startingCY={300.5}
          xOffset={xOffset}
          yOffset={yOffset}
          location="ohio"
          locationText="Cincinnati, Ohio"
          type="Remote Office"
          address1="2724 Erie Ave Suite 200,"
          address2="Cincinnati, OH 45208"
          trigger={triggerOH}
        />
      </Ohio>

      <Texas>
        <LocationSVGR
          startingX={362.591}
          startingY={49.7126}
          startingCX={362.5}
          startingCY={34.5}
          xOffset={xOffset}
          yOffset={yOffset}
          location="texas"
          locationText="Houston, Texas"
          type="Remote Office"
          address1="1301 Fannin St, Suite 2440,"
          address2="Houston, TX 77002"
          trigger={triggerTX}
        />
      </Texas>

      <California>
        <LocationSVGR
          startingX={208.186}
          startingY={188.536}
          startingCX={223.5}
          startingCY={189.5}
          xOffset={xOffset}
          yOffset={yOffset}
          location="california"
          locationText="Sunnyvale, California"
          type="Remote Office"
          address1="440 N Wolfe Road, Sunnyvale,"
          address2="CA 94085"
          trigger={triggerCA}
        />
      </California>
    </Wrapper>
  );
};

export default Locations;

const Wrapper = styled.section`
  background-color: ${colors.black};
  position: relative;

  padding-top: 12.5vw;
  padding-left: 11.3vw;
  padding-right: 11.3vw;
  padding-bottom: 73.8vw;

  ${media.tablet} {
    padding-top: 8.9vw;
    padding-left: 5.9vw;
    padding-right: 6vw;
    padding-bottom: 69.9vw;
  }

  ${media.mobile} {
    padding-top: 21.1vw;
    padding-left: 6.7vw;
    padding-right: 6.7vw;
    padding-bottom: 20.8vw;
  }
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.mobile} {
    margin-bottom: 12.533vw;
  }
`;

const Title = styled.h2`
  color: ${colors.culturedWhite60};

  ${media.fullWidth} {
    ${text.desktopLHeading}
  }

  ${media.desktop} {
    ${text.desktopLHeading}
  }

  ${media.tablet} {
    ${text.tabletMHeading}
  }

  ${media.mobile} {
    ${text.mobileSHeading}
  }
`;

const HR = styled.hr`
  background-color: ${colors.culturedWhite60};
  border: none;
  height: 1px;
  margin: 0px;

  ${media.fullWidth} {
    width: 45.417vw;
  }

  ${media.desktop} {
    width: 45.417vw;
  }

  ${media.tablet} {
    width: 40.12vw;
  }

  ${media.mobile} {
    display: none;
  }
`;

const Map = styled.div`
  opacity: 0;

  ${media.fullWidth} {
    position: absolute;
    width: 60vw;
    height: 40vw;
    top: 30vw;
    left: 20.319vw;
  }

  ${media.desktop} {
    position: absolute;
    width: 60vw;
    height: 40vw;
    top: 30vw;
    left: 20.319vw;
  }

  ${media.tablet} {
    position: absolute;
    width: 69.7vw;
    height: 47.8vw;
    top: 26.1vw;
    left: 15.1vw;
  }

  ${media.mobile} {
    opacity: 1;
    width: 100%;
    margin-bottom: 16vw;
  }
`;

const California = styled.div`
  position: absolute;
  z-index: 2;

  ${media.fullWidth} {
    height: 14.167vw;
    width: 17.153vw;
    top: 34.06vw;
    left: 11.6vw;
  }

  ${media.desktop} {
    height: 14.167vw;
    width: 17.153vw;
    top: 34.06vw;
    left: 11.6vw;
  }

  ${media.tablet} {
    width: 17.725vw;
    height: 14.611vw;
    top: 32.9vw;
    left: 6.868vw;
  }

  ${media.mobile} {
    position: relative;
    margin-bottom: 8vw;
  }
`;

const Ohio = styled.div`
  position: absolute;
  z-index: 2;

  ${media.fullWidth} {
    width: 22.431vw;
    height: 21.944vw;
    top: 26.3vw;
    left: 61.55vw;
  }

  ${media.desktop} {
    width: 22.431vw;
    height: 21.944vw;
    top: 26.3vw;
    left: 61.55vw;
  }

  ${media.tablet} {
    width: 23.234vw;
    height: 22.635vw;
    top: 24.9vw;
    left: 63.2vw;
  }

  ${media.mobile} {
    position: relative;
    margin-bottom: 8vw;
  }
`;

const Florida = styled.div`
  position: absolute;
  z-index: 2;

  ${media.fullWidth} {
    width: 22.708vw;
    height: 14.653vw;
    top: 48.8vw;
    left: 66.3vw;
  }

  ${media.desktop} {
    width: 22.708vw;
    height: 14.653vw;
    top: 48.8vw;
    left: 66.3vw;
  }

  ${media.tablet} {
    width: 23.473vw;
    height: 15.09vw;
    top: 50.6vw;
    left: 68.8vw;
  }

  ${media.mobile} {
    position: relative;
    margin-bottom: 8vw;
  }
`;

const Texas = styled.div`
  position: absolute;
  z-index: 2;

  ${media.fullWidth} {
    width: 26.215vw;
    height: 15.799vw;
    top: 56.6vw;
    left: 30.1vw;
  }

  ${media.desktop} {
    width: 26.215vw;
    height: 15.799vw;
    top: 56.6vw;
    left: 30.1vw;
  }

  ${media.tablet} {
    width: 27.066vw;
    height: 16.287vw;
    top: 58.3vw;
    left: 29.5vw;
  }

  ${media.mobile} {
    position: relative;
    margin-bottom: 8vw;
  }
`;
